import React from 'react'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

const EmployeesInConstruction: React.FC = () => {
  return (
    <>
      <SEO
        title="About the rights and obligations of workers in construction"
        description="According to the US Department of Labor, the construction industry is one of the largest sources of employment in the country, which is why it is so important to know the rights and responsibilities in the construction business."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Employees' rights & responsibilities in construction
        </Typography>
        <Typography variant="body1">
          Construction professions of the widest range in the US are in demand
          no less than in the rest of the world. Vacancies for qualified
          builders, electricians, plumbers, and carpenters are never exhausted.
          A good background of a specialist is highly valued here, which is
          adequately reflected in the salary and related bonuses.
        </Typography>
        <Typography variant="body1">
          But it is important that the rights of the builders are protected and
          the customer of the project strictly fulfills them.
        </Typography>
        <Typography variant="h2" color="blue">
          Ensuring and maintaining safe working conditions
        </Typography>
        <Typography variant="body1">
          Builders are provided with safe working conditions. Construction
          companies are responsible for ensuring the safety of workers at
          construction sites. Employees are entitled to personal protective
          equipment. These should include gloves, eye shields, hard hats, and
          ear protection. The equipment on which the builders will work must be
          serviceable. Workers have the right not to work on a construction site
          that is unsafe until the customer creates conditions for safe work.
        </Typography>
        <Typography variant="h2" color="blue">
          Equitable labor practices
        </Typography>
        <Typography variant="body1">
          For a builder to operate and protect his rights in the United States
          of America, he must have a license. It is issued not to an
          organization, but to a specific specialist. And only then the document
          is attached to the construction company. By the way, if a worker
          quits, he will need a new license.
        </Typography>
        <Typography variant="body1">
          The certifying builder must pass a 6-hour exam, based on which a
          license will be issued. The specialist needs to answer about 250
          questions related to the industry and legislation. For example, you
          need to have information about the types, basic parameters of the
          feeder, current strength, and voltage. Specialists planning to become
          engineers must pass 2 exams. The first goes immediately after
          graduation, the second - after 4 years of work.
        </Typography>
        <Typography variant="body1">
          It requires calculations, as well as knowledge of mathematical
          equations. After all, each builder has not only rights but also
          obligations at the facility, which must be fully performed by the
          contract. A practicing engineer who makes a mistake on site by making
          an incorrect drawing will be liable.
        </Typography>
        <Typography variant="body1">
          Obtaining a license is possible only in a specific specialty. The
          official license classifier contains 46 points, and each profession
          has its test and procedure for issuing a document. Just for this
          reason, contractors work in a highly specialized direction with a
          maximum of 2 licenses.
        </Typography>
        <Typography variant="body1">
          The exam is a very serious test, through which technical knowledge in
          general, and highly specialized skills, in particular, are tested.
        </Typography>
        <Typography variant="body1">
          Thus, every highly qualified specialist should own a license. To
          obtain a license for a master electrician, in addition to a diploma of
          vocational education and an internship, it is necessary to work 12
          thousand hours under the supervision of a master, take advanced
          training courses, and also pass a state exam. Then there will be a
          regular renewal of the license by confirming the level.
        </Typography>
        <Typography variant="body1">
          A person who graduated from an architectural university with a degree
          in architecture cannot present as an architect until he receives a
          license. To be admitted to the exam, in addition to the diploma, the
          applicant must submit certifying documents on working out 700 days
          (working), or 5.6 thousand hours in a design organization under the
          supervision of an experienced employee of proper competence. At the
          same time, the intern is required to acquire versatile skills in the
          main areas of architecture.
        </Typography>
        <Typography variant="h2" color="blue">
          How is builders' right to wages protected in the USA?
        </Typography>
        <Typography variant="body1">
          In the United States, there is a so-called Mechanics' Lien ("Builder's
          Deposit") law. Its essence is to protect the rights of the contractor
          to receive money. Any contractor, subcontractor, supplier of equipment
          and materials, worker, architect, gardener, plumber, or electrician
          associated with this construction may place a lien on the property if
          the master has performed work under the contract, and was not paid.
          The deposit is unconditional, and the consent of the property owner is
          not required.
        </Typography>
        <Typography variant="body1">
          However, the law also has a downside. If you are a homeowner, it is
          your responsibility to ensure that the contractor, subcontractors, and
          workers received their wages. Otherwise, one day you may find yourself
          facing the fact that your property is forcibly sold due to a minor
          debt, let's say for an unpaid plumbing repair crane.
        </Typography>
        <Typography variant="h2" color="blue">
          Is an employee protected from wrongful dismissal?
        </Typography>
        <Typography variant="body1">
          Can an employee be fired without giving a reason in USA?
        </Typography>
        <Typography variant="body1">
          USA is known for the fact that here the employer and his ward interact
          based on principle at will, which means "according to desire." Either
          party has the right to terminate the employment contract at a
          convenient time due to any reason or even without it.
        </Typography>
        <Typography variant="body1">
          This principle significantly disciplines both the employee and the
          organization. The leader understands that a highly qualified valuable
          shot can easily be lured away by a competitor, or if the attitude is
          not right, a specialist can quit.
        </Typography>
        <Typography variant="body1">
          Meanwhile, the employee is well aware that the company does not need
          to follow the dismissal procedure (draw up acts on lateness, violation
          of labor discipline, failure to perform official duties, conduct
          convincing conversations), since the manager can notify the
          subordinate of the dismissal, and after 10-15 minutes this action will
          already take effect.
        </Typography>
        <Typography variant="body1">
          A note in the contract at will indicates that the court will not
          consider claims for damages incurred during layoffs.
        </Typography>
        <Typography variant="h2" color="blue">
          Report
        </Typography>
        <Typography variant="body1">
          Builders have the right and must report if it is dangerous for them to
          be at the construction site. If the project manager or customer does
          not provide decent working conditions, builders may require the
          Occupational Safety and Health Administration (OSHA) to inspect the
          construction site. If builders contact OSHA, they cannot be subject to
          any penalty or reduction in wages by their employer.
        </Typography>
        <Typography variant="body1">
          Delayed wages or non-payment are also protected by law. And any
          builder can put a mortgage on the property and ensure the payment of
          the amount earned.
        </Typography>
        <Typography variant="body1">
          We can say that the rights of any worker in the US are protected. But
          the builder must fulfill the duties because the master can be deprived
          of a license to conduct activities.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default EmployeesInConstruction
